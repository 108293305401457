
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
  onUpdated,
  watch,
} from "vue";
import arraySort from "array-sort";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import {useI18n} from "vue-i18n";
import {arrayIndexOf} from "pusher-js/types/src/core/utils/collections";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
}

interface IHeaderConfiguration {
  column?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default {
  name: "TableBasic",
  components: {
    MaritimeVoyageSelect,
  },
  emits: [
    "current-change",
    "sort",
    "items-per-page-change",
    "eventEmitButton",
    "eventEmitPermission",
    "allFieldSelected",
  ],
  props: {
    forPermissions: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    fullData: {
      type: Array,
      default: () => [],
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 1,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canActivate: {
      type: Boolean,
      default: false,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    serverSide: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "asc",
    },
    sortLabel: {
      type: String,
      default: "",
    },
    goTo: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const buttonRef = ref();
    const popoverRef = ref();
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const element_id = ref("");
    const dialogvisible = ref(false);
    const dialogremovevisible = ref(false);
    const item_id = ref(null);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: props.total,
    });
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const i18n = useI18n();
    const locale = computed(() => i18n.locale.value);
    const editButton = computed(
      () => props.buttons.filter((x) => x.type === "EDIT").length > 0
    );
    const generateEdiButton = computed(
      () => props.buttons.filter((x) => x.type === "GENERATE_EDI").length > 0
    );
    const duplicateButton = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-1").length > 0
    );
    const duplicate2Button = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-2").length > 0
    );
    const routesButton = computed(
      () => props.buttons.filter((x) => x.type === "ROUTES").length > 0
    );
    const removeButton = computed(
      () => props.buttons.filter((x) => x.type === "REMOVE").length > 0
    );
    const activateButton = computed(
      () => props.buttons.filter((x) => x.type === "ACTIVATE").length > 0
    );
    const select_all_field = (field, item) => {
      let index = props.fullData.findIndex((obj) => obj.name == item.name);
      emit("allFieldSelected", index);
    };

    watch(props.data, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      }
    });

    const onClickEdit = (id) => {
      emit("eventEmitButton", id, "EDIT");
    };

    const onClickGenerateEdi = (id) => {
      emit("eventEmitButton", id, "GENERATE_EDI");
    };

    const consola = (data) => {
      // console.log(data);
    };

    const onClickRoutes = (id) => {
      emit("eventEmitButton", id, "ROUTES");
    };

    const onClickDuplicate = (id) => {
      item_id.value = id;
      dialogvisible.value = true;
    };

    const onClickDownload = (id) => {
      emit("eventEmitButton", id, "DOWNLOAD");
    };

    const onClickRemove = (id) => {
      item_id.value = id;
      // dialogremovevisible.value = true;
      emit("eventEmitButton", id, "REMOVE");
    };

    const onClickActivate = (id) => {
      item_id.value = id;
      emit("eventEmitButton", id, "ACTIVATE");
    };

    const onClickDuplicate2 = (id) => {
      emit("eventEmitButton", item_id, "DUPLICATE", id);
    };

    const duplicateElement = (element_id, item_id) => {
      dialogvisible.value = false;
      emit("eventEmitButton", item_id, "DUPLICATE", element_id);
    };

    const removeElement = (item_id, soft) => {
      dialogremovevisible.value = false;
      emit("eventEmitButton", item_id, "REMOVE", soft);
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }
      if ("onSort" in vnodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", { columnName: columnName, order: "desc" });
        } else {
          order.value = "asc";
          emit("sort", { columnName: columnName, order: "asc" });
        }
      } else {
        if (order.value === "asc") {
          order.value = "desc";
          arraySort(props.data, columnName, { reverse: false });
        } else {
          order.value = "asc";
          arraySort(props.data, columnName, { reverse: true });
        }
      }
      currentSort.value = columnName + order.value;
    };

    const getItems = computed(() => {
      const clone = JSON.parse(JSON.stringify(props.data));
      if (!props.serverSide) {
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.length
          ? clone.splice(startFrom, pagination.value.rowsPerPage)
          : clone;
      }
      return clone;
    });

    onMounted(() => {
      pagination.value.rowsPerPage = props.rowsPerPage;
      currentSort.value = label.value + order.value;
    });

    onUpdated(() => {
      if (props.serverSide) {
        pagination.value.rowsPerPage = props.rowsPerPage;
      }
      pagination.value.total = props.total;
    });

    const sizeChange = (val) => {
      // console.log(val);
    };

    const currentPageChange = (val) => {
      if (props.useApi) {
        emit("current-change", val);
      } else {
        if ("onCurrentChange" in vnodeProps) {
          emit("current-change", val);
        } else {
          pagination.value.page = val;
        }
      }
    };

    const setItemsPerPage = (event) => {
      if (props.useApi) {
        emit("items-per-page-change", parseInt(event.target.value));
        pagination.value.rowsPerPage = parseInt(event.target.value);
      } else {
        if ("onItemsPerPageChange" in vnodeProps) {
          emit("items-per-page-change", parseInt(event.target.value));
        } else {
          pagination.value.rowsPerPage = parseInt(event.target.value);
        }
      }
    };

    return {
      select_all_field,
      generateEdiButton,
      editButton,
      duplicateButton,
      duplicate2Button,
      routesButton,
      removeButton,
      activateButton,
      getItems,
      pagination,
      element_id,
      buttonRef,
      popoverRef,
      dialogvisible,
      dialogremovevisible,
      currentSort,
      item_id,
      sort,
      sizeChange,
      currentPageChange,
      setItemsPerPage,
      duplicateElement,
      onClickDuplicate2,
      removeElement,
      onClickGenerateEdi,
      onClickEdit,
      onClickRoutes,
      onClickDuplicate,
      onClickRemove,
      onClickActivate,
      onClickDownload,
      consola,
      locale,
    };
  },
};
